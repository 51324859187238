<template>
  <div class="registration-step-1-block">
    <div class="login-block-container">
      <div class="reg-step-form">
        <h3>{{ $lang.app.authentication }}</h3>
        <Form @submit="submit" :validation-schema="schema">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.username }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="username" type="text" autocomplete="off"
                     v-maska="'+996(###)-##-##-##'"
                     placeholder="+996(XXX)-XX-XX-XX"/>
            </div>
            <div class="input-hint">{{ $lang.app.username_hint }}: +996(ХХХ)-ХХ-ХХ-ХХ</div>
            <ErrorMessage name="username" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.password }} <i>*</i></label>
            <div class="reg-step-1-select-container">
                <Field name="password" :type="showPassword ? 'text' : 'password'"
                       v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[^\sа-яА-Я]/ }}}"
                       v-model="formData.password"
                       autocomplete="off" />
                <button type="button" @click="togglePasswordVisibility" class="toggle-password-button">
                  <img v-if="showPassword" src="@/assets/images/close-eye.png" alt="Hide Password" class="password-toggle-icon" />
                  <img v-else src="@/assets/images/eye.png" alt="Show Password" class="password-toggle-icon" />
                </button>
            </div>
            <div class="input-hint">{{ $lang.app.password_error }} </div>
            <ErrorMessage name="password" class="recovery-label-error" />
          </div>
          <div class="login-control">
            <button type="button" class="transparent-button" @click="$router.push('/')">{{ $lang.app.cancel }}</button>
            <button type="submit" class="blue-button">{{ $lang.app.login }}</button>
          </div>
          <div class="login-control">
            <router-link to="/auth/forgot-password" class="link">{{ $lang.app.forgot_password }}</router-link>
            <router-link to="/auth/forgot-email" class="link link-email">{{ $lang.app.forgot_email }}</router-link>
          </div>
        </Form>
      </div>
      <div class="note-block">
        <span class="note-title">{{ $lang.app.note_title }}</span>
        {{ $lang.app.wrong_email_note }}
      </div>
    </div>
  </div>
</template>
<script>
import { maska } from 'maska'
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
export default {
  name: 'LoginParent',
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  directives: { maska },
  data() {
    return {
      formData: {
        username: '',
        password: '',
      },
      schema: yup.object({
        username: yup.string().required()
          .matches(/\+996\([0-9]{3}\)-[0-9]{2}-[0-9]{2}-[0-9]{2}/, { excludeEmptyString: true }),
        password: yup.string().required(this.$lang.app.enter_password),
      }),
      showPassword: false,
    }
  },
  methods: {
    async submit(values, actions) {
      await this.$recaptchaLoaded()
      let recaptchaToken = await this.$recaptcha('login')
      
      this.$axios.post('/auth/login', {
        ...values,
        username: values.username.replace(/\D/g, ""),
        recaptchaToken
      })
        .then(({data}) => {
          localStorage.setItem('token', data.token)
          this.$store.commit('setToken', {
            token: data.token,
          })
          this.$store.dispatch('fetchUser').then(() => {
            this.$router.push('/admin/applications')
          })
        }).catch(error => {
          if(error.response.status === 400 || error.response.status === 422) {
            actions.setErrors({
              username: error.response.data.message,
            });
          }
        })
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  }
}
</script>
<style>
@import '../../assets/styles/form.css';
@import '../../assets/styles/auth.css';
@import '../../assets/styles/note.css';
.input-hint {
  font-size: 12px;
}

.toggle-password-button {
  width: 30px;
  height: 29px;
  margin: 0 5px;
  background: none;
  border: none;
  line-height: 0;
  outline: none;
  box-shadow: none;
  padding: 0;
}
.password-toggle-icon {
  margin: 0 1px;
  width: 24px;
  height: 24px;
}
</style>